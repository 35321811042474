import { useInvoiceManualTranscriptionTopLevelForm } from 'src/components/manifests/hooks/useInvoiceManualTranscriptionTopLevelForm';
import { useMeterForm } from 'src/components/manifests/hooks/useInvoiceManualTranscriptionMeterForm';
import { useUtilityForm } from 'src/components/manifests/hooks/useInvoiceManualTranscriptionUtilityForm';
import { useFetchAuthenticatedUser } from 'src/components/auth/hooks/useFetchAuthenticatedUser';
import { useCallback, useState } from 'react';
import { Charge, Transcription, MeterActivity, UtilityActivity } from '@amzn/taprootinvoicemanagementservice-ts-client';
import * as apiCalls from 'src/client/api-gateway';

export interface UseHydrateInvoiceManualTranscriptionFormProps {
  transcription: Transcription;
  invoiceUuid: string;
  onSubmitSuccess?: (transcriptionUuid: string) => void;
  onSubmitError?: (error?: string) => void;
}

export const useHydrateInvoiceManualTranscriptionForm = ({
  transcription,
  invoiceUuid,
  onSubmitSuccess = (transcriptionUuid: string) => {},
  onSubmitError = (error?: string) => {},
}: UseHydrateInvoiceManualTranscriptionFormProps) => {
  const topLevel = useInvoiceManualTranscriptionTopLevelForm(transcription);
  const meter = useMeterForm(transcription.meterActivities ?? []);
  const utility = useUtilityForm(transcription.utilityActivities ?? []);

  const { isFetchingUser, fetchUserCredentials } = useFetchAuthenticatedUser();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const handleReset = useCallback(() => {
    topLevel.reset();
    meter.reset();
    utility.reset();
  }, [topLevel, meter, utility]);

  const mapMeterFields = () =>
    meter.meterFields.map((field) => {
      const mappedField: MeterActivity = {
        ...field,
        meterReadingStart: field.meterReadingStart ? Number(field.meterReadingStart) : undefined,
        meterReadingEnd: field.meterReadingEnd ? Number(field.meterReadingEnd) : undefined,
      };

      if (field.meterChargeAmount || field.meterChargeCurrency) {
        mappedField.meterCharge = {
          amount: field.meterChargeAmount ? Number(field.meterChargeAmount) : undefined,
          currency: field.meterChargeCurrency,
        };
      }

      if (field.meterUsageAmount || field.meterUsageUnit) {
        mappedField.meterUsage = {
          amount: field.meterUsageAmount ? Number(field.meterUsageAmount) : undefined,
          unit: field.meterUsageUnit,
        };
      }

      return mappedField;
    });

  const mapUtilityFields = () =>
    utility.utilityFields.map((field) => {
      const mappedField: UtilityActivity = { ...field };

      if (field.utilityChargeAmount || field.utilityChargeCurrency) {
        mappedField.utilityCharge = {
          amount: field.utilityChargeAmount ? Number(field.utilityChargeAmount) : undefined,
          currency: field.utilityChargeCurrency,
        };
      }

      if (field.totalUsageAmount || field.totalUsageUnit) {
        mappedField.totalUsage = {
          amount: field.totalUsageAmount ? Number(field.totalUsageAmount) : undefined,
          unit: field.totalUsageUnit,
        };
      }

      if (field.peakDemandUsageAmount || field.peakDemandUsageUnit) {
        mappedField.peakDemand = {
          amount: field.peakDemandUsageAmount ? Number(field.peakDemandUsageAmount) : undefined,
          unit: field.peakDemandUsageUnit,
        };
      }

      return mappedField;
    });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (isSubmitDisabled) return;

    setIsSubmitting(true);
    try {
      if (utility.utilityFields.length === 0 && meter.meterFields.length === 0) {
        throw new Error('You must provide at least one meter-level or utility-level data');
      }

      const user = await fetchUserCredentials();
      if (!user) throw new Error('Could not fetch user alias');

      const convertedMeterActivities = mapMeterFields();
      const convertedUtilityActivities = mapUtilityFields();

      const charge: Charge = {
        amount: Number(topLevel.topLevelFields.totalChargeAmount),
        currency: topLevel.topLevelFields.totalChargeCurrency,
      };

      const submittedTranscription: Transcription = {
        transcriptionId: 'TRANSCRIPTION_ID_PLACE_HOLDER',
        supplierName: topLevel.topLevelFields.supplierName,
        supplierAddress: topLevel.topLevelFields.supplierAddress,
        accountNumber: topLevel.topLevelFields.accountNumber,
        invoiceDate: topLevel.topLevelFields.invoiceDate,
        billingStartDate: topLevel.topLevelFields.billingStartDate,
        billingEndDate: topLevel.topLevelFields.billingEndDate,
        totalCharge: charge,
        utilityActivities: convertedUtilityActivities,
        meterActivities: convertedMeterActivities,
      };

      const response = await apiCalls.postManualTranscription(invoiceUuid, submittedTranscription);

      if (response?.isValid) {
        setIsSubmitSuccess(true);
        // Reset the form here upon successful submission
        handleReset();
        onSubmitSuccess(response.transcriptionUuid);
      } else {
        setIsSubmitSuccess(false);
        console.error(response?.validationMessage);
        onSubmitError(response?.validationMessage);
      }
    } catch (err) {
      console.error('Error submitting invoice transcription:', err);
      setIsSubmitSuccess(false);
      onSubmitError(err as string);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isSubmitDisabled =
    topLevel.topLevelErrorCount > 0 || meter.meterErrorCount > 0 || utility.utilityErrorCount > 0;

  return {
    handleSubmit,
    handleReset,
    isLoading,
    isSubmitting,
    isSubmitDisabled,
    isSubmitSuccess,
    ...topLevel,
    ...meter,
    ...utility,
  };
};
