import { useState, useCallback } from 'react';
import {
  ErrorState,
  RemoveFieldProps,
  SetMultiFieldErrorProps,
  SetMultiFieldProps,
} from 'src/components/manifests/hooks/type';

export function useInvoiceManualTranscriptionGenericForm<T extends Record<K, any>, K extends keyof T>(
  initialData: any[],
  requiredFields: Set<K>,
  initializer: (data: any[]) => T[]
) {
  return function useGenericForm() {
    const [fields, setFields] = useState<T[]>(() => initializer(initialData));
    const [errors, setErrors] = useState<ErrorState<Set<K>[]>>(() => initializeErrors(initializer(initialData)));

    function initializeErrors(fields: T[]): ErrorState<Set<K>[]> {
      const errorFields: Set<K>[] = [];
      let errorCount = 0;

      fields.forEach((field) => {
        const fieldErrors = new Set<K>();
        (Object.keys(field) as K[]).forEach((key) => {
          if (requiredFields.has(key) && (field[key] === '' || field[key] === undefined)) {
            fieldErrors.add(key);
            errorCount++;
          }
        });
        errorFields.push(fieldErrors);
      });

      return { fields: errorFields, count: errorCount };
    }

    const setValue = useCallback((props: SetMultiFieldProps<K>) => {
      setFields((prev) =>
        prev.map((field, idx) => (idx === props.index ? { ...field, [props.name]: props.value } : field))
      );
    }, []);

    const setError = useCallback((props: SetMultiFieldErrorProps<K>) => {
      setErrors((prev) => {
        const newFields = [...prev.fields];
        const fieldErrors = new Set(newFields[props.index]);
        if (props.invalid) {
          fieldErrors.add(props.name);
        } else {
          fieldErrors.delete(props.name);
        }
        newFields[props.index] = fieldErrors;
        return { fields: newFields, count: newFields.reduce((sum, set) => sum + set.size, 0) };
      });
    }, []);

    const addField = useCallback(() => {
      setFields((prev) => [...prev, {} as T]);
      setErrors((prev) => ({
        fields: [...prev.fields, new Set(requiredFields)],
        count: prev.count + requiredFields.size,
      }));
    }, []);

    const removeField = useCallback((arg: RemoveFieldProps) => {
      setFields((prev) => prev.filter((_, idx) => idx !== arg.index));
      setErrors((prev) => {
        const newFields = prev.fields.filter((_, idx) => idx !== arg.index);
        return { fields: newFields, count: newFields.reduce((sum, set) => sum + set.size, 0) };
      });
    }, []);

    /**
     * Keep this code here in case we need to revert to the initial values.
     */
    // const reset = useCallback(() => {
    //   const initialFields = initializer(initialData);
    //   setFields(initialFields);
    //   setErrors(initializeErrors(initialFields));
    // }, []);

    const reset = useCallback(() => {
      const blankFields = fields.map(() => ({} as T));
      setFields(blankFields);
      setErrors({
        fields: blankFields.map(() => new Set(requiredFields)),
        count: requiredFields.size * blankFields.length,
      });
    }, [fields, requiredFields]);

    return {
      fields,
      errors: errors.fields,
      errorCount: errors.count,
      setValue,
      setError,
      addField,
      removeField,
      reset,
    };
  };
}
