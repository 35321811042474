import camelCase from 'lodash/camelCase';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import snakeCase from 'lodash/snakeCase';
import transform from 'lodash/transform';

import { apiGetRequest, apiPaths, apiPostRequest, coralApiGetRequest, coralApiPostRequest } from 'src/helpers/api';
import {
  IInvoiceMetadata,
  INVOICE_QUERY_KEYS,
  InvoiceOverrideMetadata,
  InvoiceQueryArgs,
  InvoiceQueryKey,
  InvoiceQueryValue,
  ProcessedInvoiceData,
  Template,
} from 'src/interface/type-def';
import { encodeTemplateId } from 'src/components/templates/templateDetails/templateDetailsUtils';
import { GetDocumentAnalysisCommandOutput } from '@aws-sdk/client-textract';
import { TemplatePropertySuggestedMapping } from 'src/components/templates/templateSuggestions/templateSuggestionsUtils';
import { WaterTemplate } from 'src/components/templates/waterTemplates/types/WaterTemplateConfiguration';
import { isLegacyTemplate } from 'src/components/templates/create/createUtils';
import { BlueprintConfiguration } from 'src/components/blueprints/types';
import {
  GetInvoiceTranscriptionResultsOutput,
  InvoiceMetadata,
  Transcription,
  BulkUploadFileType,
} from '@amzn/taprootinvoicemanagementservice-ts-client';

// Default number of manifests to fetch per page
const DEFAULT_MANIFESTS_LIMIT = 200;

enum DDB_DATA_TYPES {
  S = 'S',
  N = 'N',
}

/**
 * Some fields from API responses (ex. `last_evaluated_key`) contain keys that are references to DynamoDB
 * data types. There keys are case-sensitive, so they should be ignored when converting the API response
 * to camelCase.
 *
 * @returns Set of strings that represent keys to ignore when converting a particular response body to camelCase
 */
const getDefaultIgnoreKeys = () => {
  const ignoreKeys: Set<string> = new Set<string>().add(DDB_DATA_TYPES.S.toString()).add(DDB_DATA_TYPES.N.toString());

  return ignoreKeys;
};

export const convertKeysToCamelCase = (obj: any, ignoreKeys?: Set<string>) =>
  transform(obj, (acc: any, value: any, key: any, target: any) => {
    const camelKey = isArray(target) || ignoreKeys?.has(key) ? key : camelCase(key);

    acc[camelKey] = isObject(value) ? convertKeysToCamelCase(value, ignoreKeys) : value;
  });

export const convertKeysToSnakeCase = (obj: any, ignoreKeys?: Set<string>) =>
  transform(obj, (acc: any, value: any, key: any, target: any) => {
    const snakeKey = isArray(target) || ignoreKeys?.has(key) ? key : snakeCase(key);

    acc[snakeKey] = isObject(value) ? convertKeysToSnakeCase(value, ignoreKeys) : value;
  });

export const getInvoice = async (invoiceId: string): Promise<IInvoiceMetadata> => {
  const data = await coralApiGetRequest({ path: `${apiPaths.invoice}/${invoiceId}`, settings: {} });
  return convertKeysToCamelCase(data['invoiceMetadata']) as IInvoiceMetadata;
};

export const getTimsInvoiceMetadata = async (invoiceUuid: string): Promise<InvoiceMetadata> => {
  const data = await coralApiGetRequest({ path: `/invoice/${invoiceUuid}/metadata`, settings: {} });
  return convertKeysToCamelCase(data['invoiceMetadata']) as InvoiceMetadata;
};

export const getInvoices = async (
  lastEvaluatedKey?: string,
  pipelineStatus?: string,
  utilityId?: string,
  utilityType?: string,
  utilityTemplateId?: string
) => {
  let queryStringParameters = {};
  // TODO: Update this once the invoice metadata table has been migrated. Asana task - https://app.asana.com/0/1207424189339925/1207425848039329
  if (pipelineStatus) queryStringParameters = { ...queryStringParameters, pipeline_status: pipelineStatus };
  if (lastEvaluatedKey) queryStringParameters = { ...queryStringParameters, last_evaluated_key: lastEvaluatedKey };
  if (utilityId) queryStringParameters = { ...queryStringParameters, vendor_number: utilityId };
  if (utilityType) queryStringParameters = { ...queryStringParameters, utility_type: utilityType };
  if (utilityTemplateId) queryStringParameters = { ...queryStringParameters, utility_id: utilityTemplateId };

  const invoices = await coralApiGetRequest({
    path: apiPaths.invoices,
    settings: {
      queryStringParameters: {
        ...queryStringParameters,
        limit: DEFAULT_MANIFESTS_LIMIT,
      },
    },
  });
  return convertKeysToCamelCase(invoices, getDefaultIgnoreKeys());
};

export type GetInvoiceQueryProps = Partial<Record<InvoiceQueryKey, InvoiceQueryValue>>;
export const getManifestsWithQuery = async (queryParams: GetInvoiceQueryProps | false = false) => {
  const conditionalQueryStringParams: InvoiceQueryArgs = {};

  if (queryParams) {
    Object.entries(queryParams).forEach((param) => {
      const [key, queryValue] = param;
      if (queryValue) {
        const queryKey = INVOICE_QUERY_KEYS[key as InvoiceQueryKey];
        conditionalQueryStringParams[queryKey] = queryValue;
      }
    });
  }

  const data = await coralApiGetRequest({
    path: apiPaths.invoices,
    settings: {
      queryStringParameters: {
        ...conditionalQueryStringParams,
        limit: DEFAULT_MANIFESTS_LIMIT,
      },
    },
  });

  return convertKeysToCamelCase(data, getDefaultIgnoreKeys());
};

export const getInvoicePDF = async (invoiceUuid: string): Promise<string> => {
  const pdf = await coralApiGetRequest({ path: `${apiPaths.invoicePdfPresignedUrl}/${invoiceUuid}`, settings: {} });
  return pdf['invoicePresignedS3Url'];
};

export const getTranscriptionResults = async (invoiceUuid: string): Promise<GetInvoiceTranscriptionResultsOutput> => {
  const results: GetInvoiceTranscriptionResultsOutput = (await coralApiGetRequest({
    path: `${apiPaths.invoiceTranscriptionsResults}/${invoiceUuid}`,
    settings: {},
  })) as GetInvoiceTranscriptionResultsOutput;
  return results;
};

export const getInvoiceProcessingResults = async (invoiceId: string) => {
  const results = await coralApiGetRequest({ path: `${apiPaths.results}/${invoiceId}`, settings: {} });
  return results['results'];
};

// TODO: Update to use new coral API after: https://app.asana.com/0/1207424189339925/1207374729552469
export const getInvoiceOverrideData = async (
  invoiceId: string
): Promise<{ results: ProcessedInvoiceData; manifest: IInvoiceMetadata }> => {
  let manifestRes: IInvoiceMetadata;
  let processedInvoiceData: ProcessedInvoiceData;

  try {
    manifestRes = await getInvoice(invoiceId);
  } catch (manifestErr) {
    throw new Error(manifestErr as string);
  }

  try {
    const processingData = await coralApiGetRequest({ path: `${apiPaths.results}/${invoiceId}`, settings: {} });
    const processingResult = processingData['results'];
    processedInvoiceData = {
      account: {
        accountId: processingResult['accountId'],
        billEnd: processingResult['billEnd'],
        billStart: processingResult['billStart'],
        chargeCurrency: processingResult['chargeCurrency'],
        totalChargeAmount: processingResult['totalChargeAmount'],
        usageUnit: processingResult['usageUnit'],
        totalUsageAmount: processingResult['totalUsageAmount'],
      },
      meters: processingResult['meters'].map((meter: any) => ({
        meterId: meter.meterId,
        meterType: meter.meterType,
        usageAmount: meter.usageAmount,
        usageUnit: meter.usageUnit,
        readFromDate: meter.readFromDate,
        readToDate: meter.readToDate,
        chargeAmount: meter.chargeAmount,
        chargeCurrency: meter.chargeCurrency,
      })),
    };
  } catch (resultsErr) {
    console.error(resultsErr);
    processedInvoiceData = {
      account: {
        accountId: '',
        billEnd: '',
        billStart: '',
        chargeCurrency: '',
        totalChargeAmount: '',
        usageUnit: '',
        totalUsageAmount: '',
      },
      meters: [
        {
          meterId: '',
          meterType: '',
          usageAmount: '',
          usageUnit: '',
          readFromDate: '',
          readToDate: '',
          chargeAmount: '',
          chargeCurrency: '',
        },
      ],
    } as ProcessedInvoiceData;
  }

  const results = manifestRes?.blueprintResultOverride?.blueprintResult ?? processedInvoiceData;

  return { results, manifest: manifestRes };
};

export const postManualTranscription = async (invoiceUuid: string, manualTranscriptionBody: Transcription) => {
  const data = await coralApiPostRequest({
    path: `/invoice/${invoiceUuid}/transcription`,
    settings: {
      body: {
        transcription: manualTranscriptionBody,
      },
    },
  });
  return data;
};

export const postBulkUploadData = async (jobTitle: string, fileType: BulkUploadFileType, fileName: string) => {
  const data = await coralApiPostRequest({
    path: '/bulkUploadData',
    settings: {
      body: {
        jobTitle,
        fileType,
        fileName,
      },
    },
  });
  return data;
};

export const getBlueprints = async (
  lastEvaluatedKey?: string
): Promise<{ blueprintList: Array<BlueprintConfiguration>; lastEvaluatedKey?: string }> => {
  const settings = lastEvaluatedKey ? { queryStringParameters: { last_evaluated_key: lastEvaluatedKey } } : {};
  const data = await coralApiGetRequest({ path: apiPaths.blueprints, settings });
  return data;
};

export const getBlueprint = async (id: string, validFrom: number): Promise<{ blueprint: BlueprintConfiguration }> => {
  const settings = { queryStringParameters: { validFrom } };
  const data = coralApiGetRequest({ path: `${apiPaths.blueprint}/${id}`, settings });
  return data;
};

export const reprocessInvoicesForBlueprint = async (
  invoiceIds: Array<string>
): Promise<{ failedInvoiceIds: Array<string> }> => {
  const settings = { body: { invoiceIds } };
  const data = coralApiPostRequest({ path: `${apiPaths.reprocess}`, settings });
  return data;
};

export const upsertBlueprint = async (
  blueprint: BlueprintConfiguration,
  user: string
): Promise<{ blueprintId: string }> => {
  const blueprintId = await coralApiPostRequest({
    path: apiPaths.blueprint,
    settings: {
      body: {
        blueprintConfiguration: blueprint,
        requester: user,
      },
    },
  });

  return { blueprintId };
};

/**
 * TODO: Remove the template API calls. We no longer are using the concept of template: See Asana task: https://app.asana.com/0/1207182861944091/1207425848039325
 */

export const getTemplateSuggestions = async (invoiceId: string) => {
  const templateSuggestions = await apiGetRequest({
    path: `${apiPaths.suggestedTemplateMapping}/${invoiceId}`,
    settings: {},
  });

  // TODO: write wrapper around `Axios` client to apply convertKeysToCamelCase to response of all API requests
  // SIM: https://sim.amazon.com/issues/FootprintData-1810
  return convertKeysToCamelCase(templateSuggestions) as Array<TemplatePropertySuggestedMapping>;
};

export const reprocessInvoicesForTemplate = async (templateId: string) => {
  const failedInvoiceIds = await apiPostRequest({
    path: `${apiPaths.reprocessTemplate}/${templateId}`,
    settings: {},
  });

  return convertKeysToCamelCase(failedInvoiceIds) as { failedInvoiceIds: Array<string> };
};

/**
 * Given a template ID, returns a list that contains the utility ID and site ID, if they are present.
 *
 * @param templateId template ID to parse
 * @returns string array of associated parent template ids
 *
 */
export const getParentTemplateIds = (templateId: string): Array<string> => {
  let templateIds = templateId.split('#', 2);

  // add utility id to the site level template id
  templateIds = templateIds.map((id, index) => (index === 0 ? id : `${templateIds[index - 1]}%23${id}`));

  return templateIds;
};

export const getParentTemplates = async (templateId: string) => {
  const templateIdPromises = getParentTemplateIds(templateId).map(async (templateId) =>
    apiGetRequest({ path: `${apiPaths.template}/${templateId}`, settings: {} }).then((res) =>
      convertKeysToCamelCase(res)
    )
  );

  return Promise.all(templateIdPromises);
};

export const getTemplate = async (templateId: string): Promise<Template | WaterTemplate> => {
  const encodedTemplateId = encodeTemplateId(templateId);

  const data = await apiGetRequest({ path: `${apiPaths.template}/${encodedTemplateId}`, settings: {} });
  const res = convertKeysToCamelCase(data);

  if (isLegacyTemplate(res)) {
    return res as Template;
  }

  return res as WaterTemplate;
};

export const getTemplates = async (lastEvaluatedKey?: string) => {
  const settings = lastEvaluatedKey ? { queryStringParameters: { last_evaluated_key: lastEvaluatedKey } } : {};
  const data = await apiGetRequest({ path: apiPaths.templates, settings });

  return convertKeysToCamelCase(data, getDefaultIgnoreKeys());
};

export const upsertWaterTemplate = async (template: WaterTemplate): Promise<{ templateId: string }> => {
  const settings = {
    body: template,
  };

  const response = await apiPostRequest({ path: apiPaths.templates, settings });

  return convertKeysToCamelCase(response) as { templateId: string };
};

export const getInvoicesByTemplateId = async (templateId: string) => {
  const invoices = await apiGetRequest({
    path: apiPaths.invoices,
    settings: {
      queryStringParameters: {
        template_id: templateId,
      },
    },
  });

  return convertKeysToCamelCase(invoices, getDefaultIgnoreKeys());
};

export const getInvoiceTextractResults = async (invoiceId: string) => {
  const results = await apiGetRequest({ path: `${apiPaths.textractResult}/${invoiceId}`, settings: {} });

  return results as GetDocumentAnalysisCommandOutput;
};

export const reprocessInvoices = async (invoiceIds: string[], isTemplateVerification: boolean = false) => {
  const data = await apiPostRequest({
    path: apiPaths.reprocess,
    settings: {
      body: {
        invoices: invoiceIds,
        is_template_verification: isTemplateVerification,
      },
    },
  });
  return convertKeysToCamelCase(data);
};
