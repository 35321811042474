export const API_NAME = 'TesseractFrontendAPI';
export const TIMS_API_NAME = 'TesseractInvoiceManagementServiceAPI';
export const BASE_DOMAIN_NAME = 'tesseract.sustainability.amazon.dev';

export enum Stage {
  DEV = 'dev',
  BETA = 'beta',
  GAMMA = 'gamma',
  PROD = 'prod',
}

export enum DEV_ENV_VALUES {
  identityPoolId = 'DEV_IDENTITY_POOL_ID',
  userPoolId = 'DEV_USER_POOL_ID',
  userPoolWebClientId = 'DEV_USER_POOL_WEB_CLIENT_ID',
  userPoolOAuthDomain = 'DEV_USER_POOL_OAUTH_DOMAIN',
  apiGatewayEndpoint = 'DEV_API_GATEWAY_ENDPOINT',
  timsApiEndpoint = 'DEV_TIMS_API_ENDPOINT',
  bulkUploadBucket = 'DEV_TIMS_BULK_UPLOAD_BUCKET',
}

type RemoteStages = Exclude<Stage, Stage.DEV>;

interface StageValues {
  identityPoolId: string;
  userPoolId: string;
  userPoolWebClientId: string;
  userPoolOAuthDomain: string;
  apiGatewayEndpoint: string;
  timsApiEndpoint: string;
  bulkUploadBucket: string;
}

type RemoteStageValues = Record<RemoteStages, StageValues>;

export const remoteStageConfig: RemoteStageValues = {
  [Stage.PROD]: {
    identityPoolId: 'us-east-1:235e4efd-4356-498b-a4a0-884e47edcfda',
    userPoolId: 'us-east-1_7hkqPMFvI',
    userPoolWebClientId: '6et6hchv0ufqqtg19odqe349ho',
    userPoolOAuthDomain: 'tims-website-prod.auth.us-east-1.amazoncognito.com',
    apiGatewayEndpoint: 'https://gsbubl70ja.execute-api.us-east-1.amazonaws.com/prod',
    timsApiEndpoint: 'https://44b4xbto6g.execute-api.us-east-1.amazonaws.com/prod',
    bulkUploadBucket: 'tims-intermediate-bulk-upload-invoices-prod-us-east-1',
  },
  [Stage.GAMMA]: {
    identityPoolId: 'us-east-1:9fa9ab26-1b2a-4183-a43a-814b90ccdb21',
    userPoolId: 'us-east-1_Q6lL7CTev',
    userPoolWebClientId: 'qmcppvq87np7uoitsd42f4p0p',
    userPoolOAuthDomain: 'tims-website-gamma.auth.us-east-1.amazoncognito.com',
    apiGatewayEndpoint: 'https://wjpcs8lk7k.execute-api.us-east-1.amazonaws.com/gamma',
    timsApiEndpoint: 'https://043jlhmz4e.execute-api.us-east-1.amazonaws.com/gamma',
    bulkUploadBucket: 'tims-intermediate-bulk-upload-invoices-gamma-us-east-1',
  },
  [Stage.BETA]: {
    identityPoolId: 'us-west-2:8d2fb556-7e90-45b8-8178-9deef3db7c6e',
    userPoolId: 'us-west-2_uzkigF8CE',
    userPoolWebClientId: '5jj69qbla8u8cf2v1g8nl56kti',
    userPoolOAuthDomain: 'tims-website-beta.auth.us-west-2.amazoncognito.com',
    apiGatewayEndpoint: 'https://6w880jpgf0.execute-api.us-west-2.amazonaws.com/beta',
    timsApiEndpoint: 'https://ci112pksx8.execute-api.us-west-2.amazonaws.com/beta',
    bulkUploadBucket: 'tims-intermediate-bulk-upload-invoices-beta-us-west-2',
  },
};

export const getStage = (): Stage => {
  switch (window.location.hostname) {
    case BASE_DOMAIN_NAME:
      return Stage.PROD;
    case `${Stage.GAMMA}.${BASE_DOMAIN_NAME}`:
      return Stage.GAMMA;
    case `${Stage.BETA}.${BASE_DOMAIN_NAME}`:
      return Stage.BETA;
    default:
      return Stage.DEV;
  }
};

export const getProjectRegion = () => {
  switch (getStage()) {
    case Stage.BETA:
    case Stage.DEV:
      return 'us-west-2';
    default:
      return 'us-east-1';
  }
};

export const getDevStageEnvOverrides = (): StageValues => ({
  identityPoolId: process.env.DEV_IDENTITY_POOL_ID ?? remoteStageConfig[Stage.BETA].identityPoolId,
  userPoolId: process.env.DEV_USER_POOL_ID ?? remoteStageConfig[Stage.BETA].userPoolId,
  userPoolWebClientId: process.env.DEV_USER_POOL_WEB_CLIENT_ID ?? remoteStageConfig[Stage.BETA].userPoolWebClientId,
  userPoolOAuthDomain: process.env.DEV_USER_POOL_OAUTH_DOMAIN ?? remoteStageConfig[Stage.BETA].userPoolOAuthDomain,
  apiGatewayEndpoint: process.env.DEV_API_GATEWAY_ENDPOINT ?? remoteStageConfig[Stage.BETA].apiGatewayEndpoint,
  timsApiEndpoint: process.env.DEV_TIMS_API_ENDPOINT ?? remoteStageConfig[Stage.BETA].timsApiEndpoint,
  bulkUploadBucket: process.env.DEV_TIMS_BULK_UPLOAD_BUCKET ?? remoteStageConfig[Stage.BETA].bulkUploadBucket,
});

export const getDynamicAwsExports = (defaultExports: any) => {
  const currentStage = getStage();
  const config = currentStage === Stage.DEV ? getDevStageEnvOverrides() : remoteStageConfig[currentStage];

  const {
    identityPoolId,
    userPoolId,
    userPoolWebClientId,
    userPoolOAuthDomain,
    apiGatewayEndpoint,
    timsApiEndpoint,
    bulkUploadBucket,
  } = config;

  return {
    ...defaultExports,
    aws_project_region: getProjectRegion(),
    aws_cognito_identity_pool_id: identityPoolId,
    aws_cognito_region: getProjectRegion(),
    aws_user_pools_id: userPoolId,
    aws_user_pools_web_client_id: userPoolWebClientId,
    aws_user_files_s3_bucket_region: getProjectRegion(),
    oauth: {
      ...defaultExports.oauth,
      domain: userPoolOAuthDomain,
      redirectSignIn: `${window.location.origin}`,
      redirectSignOut: `${window.location.origin}`,
    },
    Auth: {
      identityPoolId,
      region: getProjectRegion(),
      userPoolId,
      userPoolWebClientId,
    },
    AWSS3: {
      region: getProjectRegion(),
    },
    aws_cloud_logic_custom: [
      {
        name: API_NAME,
        endpoint: apiGatewayEndpoint,
        region: getProjectRegion(),
      },
      {
        name: TIMS_API_NAME,
        endpoint: timsApiEndpoint,
        region: getProjectRegion(),
      },
    ],
  };
};
