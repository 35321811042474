import { Box, Container, ExpandableSection, Header } from '@amzn/awsui-components-react';
import {
  Charge,
  MeterActivity,
  TranscriptionResult,
  Usage,
  UtilityActivity,
} from '@amzn/taprootinvoicemanagementservice-ts-client';
import React from 'react';
import { formatTimestamp } from '../utils/timestampUtils';

export const TranscriptionDetails = (transcriptionResult: TranscriptionResult, transcriptionIndex: number) => (
  <ExpandableSection
    defaultExpanded
    variant="stacked"
    headerText={`Transcription ${
      transcriptionResult.result?.transcriptionId ? `${transcriptionResult.result?.transcriptionId} ` : ` `
    }received on ${formatTimestamp(transcriptionResult.transcribedTimestamp)}`}
    key={`transcriptionResult${transcriptionIndex} UTC`}
  >
    <Box variant="h4"> Transcription Details </Box>
    {formatNullValues('Supplier Name', transcriptionResult.result?.supplierName)}
    {formatNullValues('Supplier Address', transcriptionResult.result?.supplierAddress)}
    {formatNullValues('Account Number', transcriptionResult.result?.accountNumber)}
    {formatNullValues('Invoice Date', transcriptionResult.result?.invoiceDate)}
    {formatNullValues('Billing Start Date', transcriptionResult.result?.billingStartDate)}
    {formatNullValues('Billing End Date', transcriptionResult.result?.billingEndDate)}
    {ChargeDetails('Total Charge', transcriptionResult.result?.totalCharge)}
    {UtilityLevelDetails(transcriptionResult.result?.utilityActivities, transcriptionIndex)}
    {MeterLevelDetails(transcriptionResult.result?.meterActivities, transcriptionIndex)}
  </ExpandableSection>
);

export const UtilityLevelDetails = (utilityActivities: UtilityActivity[] | undefined, transcriptionIndex: number) => (
  <Box>
    <Box variant="strong" display="block">
      {' '}
      Utility Details{' '}
    </Box>
    {(!utilityActivities || utilityActivities.length === 0) && (
      <Box variant="div" display="inline">
        No Utility Details
      </Box>
    )}
    {utilityActivities?.map((utilityActivity: UtilityActivity, index: number) => (
      <Box display="block" key={`utilityActivity-${transcriptionIndex}-${index}`}>
        <ExpandableSection defaultExpanded headerText={`Utility ${index + 1}`}>
          {formatNullValues('Utility Type', utilityActivity.utilityType)}
          {ChargeDetails('Utility Charge', utilityActivity?.utilityCharge)}
          {UsageDetails('Utility Usage', utilityActivity?.totalUsage)}
          {UsageDetails('Utility Peak Demand', utilityActivity?.peakDemand)}
        </ExpandableSection>
      </Box>
    ))}
  </Box>
);

export const MeterLevelDetails = (meterActivities: MeterActivity[] | undefined, transcriptionIndex: number) => (
  <Box>
    <Box variant="strong" display="block">
      {' '}
      Meter Details{' '}
    </Box>
    {(!meterActivities || meterActivities.length === 0) && (
      <Box variant="div" display="inline">
        No Meter Details
      </Box>
    )}
    {meterActivities?.map((meterActivity: MeterActivity, index: number) => (
      <Box display="block" key={`meterActivity-${transcriptionIndex}-${index}`}>
        <ExpandableSection defaultExpanded headerText={`Meter ${index + 1}`}>
          {formatNullValues('Meter Number', meterActivity.meterNumber)}
          {formatNullValues('Meter Utility Type', meterActivity?.meterUtilityType)}
          {formatNullValues('Meter Read Start Date', meterActivity?.meterReadStartDate)}
          {formatNullValues('Meter Read End Date', meterActivity?.meterReadEndDate)}
          {formatNullValues('Meter Reading Start', meterActivity?.meterReadingStart)}
          {formatNullValues('Meter Reading End', meterActivity?.meterReadingEnd)}
          {ChargeDetails('Meter Charge', meterActivity?.meterCharge)}
          {UsageDetails('Meter Usage', meterActivity?.meterUsage)}
        </ExpandableSection>
      </Box>
    ))}
  </Box>
);

export const ChargeDetails = (displayName: String, charge: Charge | undefined) => (
  <Box display="block">
    {formatNullValues(`${displayName} Amount`, charge?.amount, true)}
    {formatNullValues(`${displayName} Currency`, charge?.currency, true)}
  </Box>
);

export const UsageDetails = (displayName: String, usage: Usage | undefined) => (
  <Box display="block">
    {formatNullValues(`${displayName} Amount`, usage?.amount, true)}
    {formatNullValues(`${displayName} Unit`, usage?.unit, true)}
  </Box>
);

export function formatNullValues<T>(
  displayName: string,
  value: T | null | undefined,
  inlineDisplay?: boolean
): React.JSX.Element {
  return (
    <Box display={inlineDisplay ? 'inline' : 'block'}>
      <Box variant="awsui-key-label" display="inline">
        {' '}
        {displayName}:{' '}
      </Box>
      <Box variant="div" display="inline">
        {value ?? 'null'}
      </Box>
    </Box>
  );
}
