import { Alert, Box, ColumnLayout, Container, Header, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { InvoiceMetadata, Transcription } from '@amzn/taprootinvoicemanagementservice-ts-client';
import { AppContext } from '../App';
import { useFetchPdf } from '../common-components/hooks/useFetchPdf';
import { BreadcrumbGroupWithRouting } from '../breadcrumbs/BreadcrumbGroupWithRouting';
import { INVOICE_BREADCRUMBS, ROOT_BREADCRUMB } from '../breadcrumbs/breadcrumbs';
import { InvoiceTranscriptions } from './transcriptions/invoice-transcriptions';
import { SplitPanelContext } from 'src/components/split-panel/SplitPanelProvider';
import { InvoiceManualTranscriptionForm } from 'src/components/manifests/manual-transcription/InvoiceManualTranscriptionForm';
import { PDFViewer } from 'src/components/common-components/PDFViewer';
import { InvoiceManualTranscriptionSplitPanel } from 'src/components/manifests/manual-transcription/InvoiceManualTranscriptionSplitPanel';
import { useFetchTimsInvoiceMetadataByUuid } from 'src/components/manifests/hooks/useFetchTimsInvoiceMetadataByUuid';
import { formatTimestamp } from 'src/components/manifests/utils/timestampUtils';
import { useFetchInvoiceTranscriptionResults } from 'src/components/manifests/hooks/useFetchInvoiceTranscriptionResults';

export interface InvoiceDetailsParams {
  invoiceUuid: string;
}

const InvoiceMetadataTable = ({ invoiceMetadata }: { invoiceMetadata: InvoiceMetadata }) => (
  <SpaceBetween direction="vertical" size="l">
    <ColumnLayout columns={3} variant="text-grid">
      <div>
        <Box variant="small">Invoice UUID</Box>
        <div>{invoiceMetadata.invoiceUuid || '-'}</div>
      </div>

      <div>
        <Box variant="small">Invoice PDF Name</Box>
        <div>{invoiceMetadata.invoicePdfName || '-'}</div>
      </div>

      <div>
        <Box variant="small">Location Code</Box>
        <div>{invoiceMetadata.locationCode || '-'}</div>
      </div>

      <div>
        <Box variant="small">Artifact Id/Barcode</Box>
        <div>{invoiceMetadata.artifactId || '-'}</div>
      </div>

      <div>
        <Box variant="small">Supplier Id</Box>
        <div>{invoiceMetadata.supplierId || '-'}</div>
      </div>

      <div>
        <Box variant="small">Utility Type</Box>
        <div>{invoiceMetadata.utilityType || '-'}</div>
      </div>
    </ColumnLayout>
    <ColumnLayout columns={3} variant="text-grid">
      <div>
        <Box variant="small">Invoice Entity</Box>
        <div>{invoiceMetadata.invoiceEntity || '-'}</div>
      </div>

      <div>
        <Box variant="small">Invoice Type</Box>
        <div>{invoiceMetadata.invoiceType || '-'}</div>
      </div>

      <div>
        <Box variant="small">Invoice Source</Box>
        <div>{invoiceMetadata.invoiceSource || '-'}</div>
      </div>

      <div>
        <Box variant="small">Invoice Ingestion Timestamp (UTC)</Box>
        <div>{formatTimestamp(invoiceMetadata.ingestionTimestamp)}</div>
      </div>
    </ColumnLayout>
  </SpaceBetween>
);

export const InvoiceDetails = () => {
  const { invoiceUuid } = useParams<InvoiceDetailsParams>();
  const { setNotificationsItems } = useContext(AppContext);
  const { setSplitPanel, showSplitPanel } = useContext(SplitPanelContext);

  // Fetch invoice metadata
  const { invoiceMetadata, invoiceMetadataLoading, invoiceMetadataError, fetchTimsInvoiceMetadataByUuid } =
    useFetchTimsInvoiceMetadataByUuid();

  // Fetch PDF
  const { pdf, fetchPdf, pdfErrorMessage, pdfLoading } = useFetchPdf();

  // Fetch transcriptions
  const { isLoadingTranscriptions, invoiceTranscriptionResults, errorStatus, fetchInvoiceTranscriptionResults } =
    useFetchInvoiceTranscriptionResults();

  const [showTranscriptionForm, setShowTranscriptionForm] = useState(false);

  // State for the default transcription (start with a blank transcription)
  const [defaultTranscription, setDefaultTranscription] = useState<Transcription>({
    accountNumber: '',
    supplierAddress: '',
    supplierName: '',
    billingStartDate: '',
    billingEndDate: '',
    invoiceDate: '',
    totalCharge: undefined,
    utilityActivities: [],
    meterActivities: [],
  });

  useEffect(
    () => () => {
      setNotificationsItems([]);
    },
    [setNotificationsItems]
  );

  useEffect(() => {
    fetchTimsInvoiceMetadataByUuid(invoiceUuid);
  }, [invoiceUuid, fetchTimsInvoiceMetadataByUuid]);

  useEffect(() => {
    fetchPdf(invoiceUuid);
  }, [invoiceUuid, fetchPdf]);

  useEffect(() => {
    fetchInvoiceTranscriptionResults(invoiceUuid);
  }, [invoiceUuid, fetchInvoiceTranscriptionResults]);

  useEffect(() => {
    if (!isLoadingTranscriptions && !errorStatus && invoiceTranscriptionResults) {
      if (invoiceTranscriptionResults.length > 0 && invoiceTranscriptionResults[0].result) {
        setDefaultTranscription(invoiceTranscriptionResults[0].result);
      }
      setShowTranscriptionForm(true);
    } else if (!isLoadingTranscriptions && errorStatus) {
      setShowTranscriptionForm(true); // Show form even if there's an error, so user can input manually
    }
  }, [isLoadingTranscriptions, errorStatus, invoiceTranscriptionResults]);

  useEffect(() => {
    setSplitPanel(
      <InvoiceManualTranscriptionSplitPanel key={JSON.stringify(defaultTranscription)}>
        {isLoadingTranscriptions ? (
          <div style={{ padding: '1rem', textAlign: 'left' }}>
            <Spinner /> Loading the latest transcription result...
          </div>
        ) : showTranscriptionForm ? (
          <InvoiceManualTranscriptionForm invoiceUuid={invoiceUuid} transcription={defaultTranscription} />
        ) : null}
      </InvoiceManualTranscriptionSplitPanel>
    );
    showSplitPanel(true);
    return () => {
      setSplitPanel(undefined);
      showSplitPanel(false);
    };
  }, [
    invoiceUuid,
    defaultTranscription,
    setSplitPanel,
    showSplitPanel,
    isLoadingTranscriptions,
    showTranscriptionForm,
  ]);

  return (
    <SpaceBetween size="l">
      <BreadcrumbGroupWithRouting
        items={[
          { text: ROOT_BREADCRUMB.homepage.text, href: ROOT_BREADCRUMB.homepage.getHref() },
          {
            text: INVOICE_BREADCRUMBS.invoiceDetails.text,
            href: INVOICE_BREADCRUMBS.invoiceDetails.getHref(invoiceUuid),
          },
        ]}
      />

      <Container header={<Header variant="h2">Invoice metadata</Header>}>
        <SpaceBetween size="xs">
          <Container>
            {invoiceMetadataLoading ? (
              <div className="banner">Loading invoice details...</div>
            ) : invoiceMetadataError ? (
              <Alert type="error" header="Error Fetching Metadata">
                {invoiceMetadataError}
              </Alert>
            ) : invoiceMetadata ? (
              <InvoiceMetadataTable invoiceMetadata={invoiceMetadata} />
            ) : (
              <div>No invoice details found.</div>
            )}
          </Container>
        </SpaceBetween>
      </Container>

      <Container header={<Header variant="h2">Invoice file</Header>}>
        <SpaceBetween size="xs">
          <Container>
            <PDFViewer file={pdf} />
          </Container>
        </SpaceBetween>
      </Container>
      <InvoiceTranscriptions />
    </SpaceBetween>
  );
};
